import { useDesktopMenuService } from "./desktopMenuService";
import { useMobileMenuService } from "./mobileMenuService";
import { useMenuService } from "./menuService";

const useMenu = () => {
  const subMenuToggleButtons = document.querySelectorAll('.sub-menu__sub-menu-toggle') as NodeListOf<HTMLButtonElement>;
  const firstLevelToggleButtons = document.querySelectorAll('.sub-menu__first-level-toggle') as NodeListOf<HTMLButtonElement>;
  const firstLevelButtons = document.querySelectorAll('.menu__link') as NodeListOf<HTMLElement>;
  const previousSubmenuButtons = document.querySelectorAll('.sub-menu__item--back-link') as NodeListOf<HTMLButtonElement>;

  const { desktopMediaQuery, closeOnEsc, closeWhenClickingOutside, toggleMegaMenu, toggleSubMenuItemDesktop } = useDesktopMenuService();
  const { mobileToggleButton, toggleMobileMenu, openSubMenuMobile, closeSubMenuMobile } = useMobileMenuService();
  const { initMenuForDesktopOrMobile, resetMenu } = useMenuService();

  const init = () => {
    firstLevelButtons.forEach(button => button.addEventListener('click', e => {
      resetMenu();

      if (desktopMediaQuery.matches) {
        toggleMegaMenu(button, e);
      } else {
        openSubMenuMobile(button, e, true);
      }
    }));

    subMenuToggleButtons.forEach(button => button.addEventListener('click', e => {
      if (desktopMediaQuery.matches) {
        toggleSubMenuItemDesktop(button, e);
      } else {
        openSubMenuMobile(button, e);
      }
    }));

    firstLevelToggleButtons.forEach(button => {
      button.addEventListener('click', e => {
        if (!desktopMediaQuery.matches) {
          openSubMenuMobile(button, e, true);
        }
      });
    });

    window.addEventListener('keyup', closeOnEsc);
    document.addEventListener('click', closeWhenClickingOutside);
    mobileToggleButton.addEventListener('click', toggleMobileMenu);
    previousSubmenuButtons.forEach(button => button.addEventListener('click', e => closeSubMenuMobile(button, e)));
    window.addEventListener("resize", () => { initMenuForDesktopOrMobile(); });

    initMenuForDesktopOrMobile();
  };

  init();
};

useMenu();