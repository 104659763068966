export const getParentsOfTypeUntilClass = (element, tagName, className) => {
  const parents = [];
  let currentElement = element.parentElement;

  while (currentElement && !currentElement.classList.contains(className)) {
    if (currentElement.tagName.toLowerCase() === tagName.toLowerCase()) {
      parents.push(currentElement);
    }
    currentElement = currentElement.parentElement;
  }

  return parents;
};

export const getSiblingByClass = (element, className) => {
  return [...element.parentNode.children].find(
    sibling => sibling !== element && sibling.classList.contains(className)
  );
};

export const showElement = (element, duration, callback = null) => {
  // Hide the element and store the height
  element.style.position = 'fixed';
  element.style.visibility = 'hidden';
  element.style.height = `auto`;

  const { height } = getComputedStyle(element);

  element.style.position = null;
  element.style.visibility = null;
  element.style.height = 0;

  // Change height from 0 to the stored value
  setTimeout(() => {
    element.style.height = height;
  }, 1);
  setTimeout(() => {
    element.style.height = 'auto';

    if (callback) {
      callback();
    }
    // Set height to auto when the transition is done. These timeouts must match the transition duration
  }, duration - 1);
};

export const hideElement = (element, callback = null) => {
  // Get the actual height of the element and set it
  element.style.height = `auto`;
  const { height } = getComputedStyle(element);
  element.style.height = height;

  // Trigger the transition by setting height to 0
  setTimeout(() => {
    element.style.height = 0;

    if (callback) {
      callback();
    }
  }, 1);
};