import { hideElement, showElement } from '@utils/helpers';

export const useDesktopMenuService = () => {
  const desktopMediaQuery = window.matchMedia('(min-width: 1320px)');
  const duration = 200;
  const subMenuOpenClass = "sub-menu--open";
  const subMenuClosedClass = "sub-menu--closed";
  const megaMenuOpenClass = "menu__item--selected-parent";
  const subMenuIconClass = "icon--rotate";

  const toggleMegaMenu = (button: HTMLElement, e: Event | null = null): void => {
    if (e !== null) {
      e.preventDefault();
    }

    const curentMenuItem = button.parentElement as HTMLElement; // Parent is the <li> containing the button
    const currentSubMenu = button.nextElementSibling as HTMLDivElement; // Sibling is the <ul> containing the submenu
    const alreadyOpen = currentSubMenu.classList.contains(subMenuOpenClass);
    // Only get the first level submenus
    const firstLevelSubmenus: HTMLElement[] = Array.from(document.querySelectorAll(".menu > .menu__list > .menu__item > .sub-menu--open"));

    if (alreadyOpen) {
      curentMenuItem.classList.remove(megaMenuOpenClass);
      currentSubMenu.classList.remove(subMenuOpenClass);
      currentSubMenu.classList.add(subMenuClosedClass);
      document.body.classList.remove("menu-open");
    } else {
      document.body.classList.add("menu-open");
      // Hide all other open submenus
      firstLevelSubmenus.forEach(item => {
        item.parentElement?.classList.remove(megaMenuOpenClass);
        item.classList.remove(subMenuOpenClass);
        setTimeout(() => { item.classList.add(subMenuClosedClass); }, duration);
      });
      // Open the clicked submenu, but set to block first to trigger transition
      currentSubMenu.classList.remove(subMenuClosedClass);
      setTimeout(() => { currentSubMenu.classList.add(subMenuOpenClass); }, 1);
      curentMenuItem.classList.add(megaMenuOpenClass);
    }
  };

  const toggleSubMenuItemDesktop = (button: HTMLElement, e: Event | null = null): void => {
    if (e !== null) {
      e.preventDefault();
    }

    // Parent is <a>, sibling is the <div> we want
    const currentSubMenu: HTMLElement = button.parentElement?.nextElementSibling as HTMLElement;
    const currentIcon = button.getElementsByClassName("icon")[0];

    if (currentSubMenu.classList.contains(subMenuOpenClass)) {
      hideElement(currentSubMenu);
      currentSubMenu.classList.remove(subMenuOpenClass);
      currentIcon.classList.remove(subMenuIconClass);
    } else {
      showElement(currentSubMenu, 200);
      currentSubMenu.classList.add(subMenuOpenClass);
      currentIcon.classList.add(subMenuIconClass);
    }
  };

  // Close on escape -------------------------------
  const closeOnEsc = (e: KeyboardEvent): void => {
    if (desktopMediaQuery.matches && !e.defaultPrevented && e.key === 'Escape') {
      e.preventDefault();
       closeOpenMenuItems();
    }
  };

  // Close when clicking outside -------------------------------
  const closeWhenClickingOutside = (e: MouseEvent): void => {
    if (desktopMediaQuery.matches && e.target instanceof Element) {
      const headerContainer = document.querySelector('.header__container') as HTMLDivElement;

      if (!headerContainer.contains(e.target)) {
        closeOpenMenuItems();
      }
    }
  };

  const closeOpenMenuItems = (): void => {
    // Close open menus
    Array.from(document.getElementsByClassName(subMenuOpenClass)).forEach(item => {
      item.classList.remove(subMenuOpenClass);
    });
    // Rotate icons back
    Array.from(document.getElementsByClassName(`menu ${subMenuIconClass}`)).forEach(item => {
      item.classList.remove(subMenuIconClass);
    });
    // Reset active menu items
    Array.from(document.getElementsByClassName(megaMenuOpenClass)).forEach(item => {
      item.classList.remove(megaMenuOpenClass);
    });
  };

  return {
    desktopMediaQuery,
    closeOnEsc,
    closeWhenClickingOutside,
    toggleMegaMenu,
    toggleSubMenuItemDesktop,
    closeOpenMenuItems,
    subMenuClosedClass,
    subMenuOpenClass,
  };
};