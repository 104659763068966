import { debounce } from "@utils/debounce";
import { useDesktopMenuService } from "./desktopMenuService";
import { useDisturbanceService } from "@features/Layout/Features/Header/Features/Disturbance/Scripts/disturbance";

export const useMenuService = () => {
  const { subMenuClosedClass, subMenuOpenClass, desktopMediaQuery } = useDesktopMenuService();
  const { setDisturbanceHeightVariable } = useDisturbanceService();

  // Since the submenu is always hidden on desktop, and always visible on mobile we need to
  // handle the resize event and make sure the menu's state is correct when switching
  const initMenuForDesktopOrMobile = debounce((): void => {
    resetMenu();
    const isAboveThreshold = desktopMediaQuery.matches;
    setMenuForDesktopOrMobile(isAboveThreshold);
  }, 10);

  const setMenuForDesktopOrMobile = (isAboveThreshold: boolean) => {
    const elements = document.querySelectorAll(`.sub-menu`);
    const firstLevel = document.querySelector('.menu__link + .sub-menu');

    // Mobile and desktop uses the first level differently,
    // so we need to add the open/closed classes back and forth
    if (isAboveThreshold) {
      elements.forEach(element => {
        element.classList.add(subMenuClosedClass);
      });
      firstLevel?.classList.add(subMenuClosedClass);
      firstLevel?.classList.remove(subMenuOpenClass);

    } else {
      const elements = document.querySelectorAll(`.sub-menu`);
      elements.forEach(element => {
        element.classList.remove(subMenuClosedClass);
      });
      elements.forEach(subMenu => {
        if (subMenu.querySelector('.sub-menu__item--selected')) {
          // Open the submenu if it contains the selected item
          subMenu?.classList.add(subMenuOpenClass);
        }
      });
    }
  };

  const resetMenu = () => {
    // // Reset state if we've switched between mobile and desktop
    document.querySelectorAll('.menu__list--open').forEach(menu => {
      menu.style.display = "none";
      menu.classList.remove('menu__list--open');
    });
    document.querySelectorAll('.menu__item--selected-parent').forEach(menu => {
      menu.classList.remove('menu__item--selected-parent');
    });

    document.querySelector('.menu__toggle-button--open')?.classList.remove('menu__toggle-button--open');

    document.body.classList.remove("menu-open");
    document.body.classList.remove("no-scroll");
    setDisturbanceHeightVariable();
  };

  return { initMenuForDesktopOrMobile, resetMenu };
};