import { getSiblingByClass } from "@utils/helpers";

export const useMobileMenuService = () => {
  const duration = 200;
  const mobileToggleButton = document.querySelector(".menu__toggle-button") as HTMLElement;
  const menuList = document.querySelector(".menu__list") as HTMLElement;
  const mobileToggleButtonOpenClass = "menu__toggle-button--open";
  const menuListOpenClass = "menu__list--open";
  const menuListNavigatedAwayClass = "menu__list--navigated-away";
  const menuLinkClass = "menu__link";
  const subMenuOpenClass = "sub-menu--open";
  const subMenuNavigatedAwayClass = "sub-menu--navigated-away";
  const subMenuToggleButtonClass = "sub-menu__sub-menu-toggle";
  const subMenuFirstLevelToggleClass = "sub-menu__first-level-toggle";

  let parentName: string;

  const toggleMobileMenu = () => {
    if (menuList.classList.contains(menuListOpenClass)) {
      document.body.classList.remove("no-scroll");
      document.body.classList.remove("menu-open");
      mobileToggleButton.classList.remove(mobileToggleButtonOpenClass);
      menuList.classList.remove(menuListOpenClass);
      setTimeout(() => { menuList.style.display = "none"; }, duration);
    } else {
      document.body.classList.add("no-scroll");
      document.body.classList.add("menu-open");
      mobileToggleButton.classList.add(mobileToggleButtonOpenClass);
      menuList.style.display = "block";
      setTimeout(() => { menuList.classList.add(menuListOpenClass); }, 1);
    }

    openToCurrentPage();
  };

  const openSubMenuMobile = (button: HTMLElement, e: Event, isFirstLevelLink: boolean = false) => {
    stopEvent(e);

    if (isFirstLevelLink || isFirstLevelLink && button.classList.contains(subMenuToggleButtonClass)) {
      // First level link clicked, just set navigated away
      document.querySelector(`.${menuListOpenClass}`)?.classList.add(menuListNavigatedAwayClass);
    } else {
      // Sub level clicked, set the last found open submenu to navigated away
      const openSubMenus = document.querySelectorAll(`.${subMenuOpenClass}`);
      openSubMenus[openSubMenus.length - 1].classList.add(subMenuNavigatedAwayClass);
    }

    const subMenu = getSubMenu(button, isFirstLevelLink);
    subMenu.classList.add(subMenuOpenClass);

    // Set the link name for the go-back-link
    parentName = getButtonText(button, isFirstLevelLink) as string;
    const parentLink = subMenu.querySelector(".sub-menu__item--back-link a span:last-child") as HTMLElement;
    parentLink.innerText = parentName;
  };

  const closeSubMenuMobile = (button: HTMLElement, e: Event | null = null) => {
    if (e) {
      stopEvent(e);
    }

    button.closest(`.${subMenuOpenClass}`)?.classList.remove(subMenuOpenClass);
    const navigatedSubmenu = button.closest(`.${subMenuNavigatedAwayClass}`);

    if (navigatedSubmenu) {
      navigatedSubmenu.classList.remove(subMenuNavigatedAwayClass);

    } else {
      // If navigatedSubmenu is null the parent is the top container page
      button.closest(`.${menuListNavigatedAwayClass}`)?.classList.remove(menuListNavigatedAwayClass);
    }
  };

  const getSubMenu = (button: HTMLElement, isFirstLevelLink: boolean) => {
    if (isFirstLevelLink) {
      if (button.classList.contains(subMenuFirstLevelToggleClass)) {
        // First level chevron was clicked
        return button.parentElement?.nextElementSibling as HTMLElement;
      }
      if (button.classList.contains(menuLinkClass)) {
        // First level link was clicked
        return button.nextElementSibling as HTMLElement;
      }
    }
    // Sub menu chevron was clicked
    return button.parentElement?.nextElementSibling as HTMLElement;
  };

  const getButtonText = (button: HTMLElement, isFirstLevelLink: boolean) => {
    if (isFirstLevelLink) {
      if (button.classList.contains(subMenuFirstLevelToggleClass)) {
        // First level chevron was clicked
        return button.parentElement?.querySelector("span")?.textContent;
      }
      if (button.classList.contains(menuLinkClass)) {
        // First level link was clicked
        return button.querySelector("span")?.textContent;
      }
    }

    return button.parentElement?.querySelector("span")?.textContent;
  };

  const openToCurrentPage = () => {
    const openSubMenus = document.querySelectorAll(`.${subMenuOpenClass}`);

    // If there are no open submenus, we're at the root level
    if (!openSubMenus.length) {
      return;
    }

    // Set root level to navigated away
    menuList?.classList.add(menuListNavigatedAwayClass);

    // set all but the last submenu to navigated away
    for (let i = 0; i < openSubMenus.length - 1; i++) {
      const currentSubMenu = openSubMenus[i];
      currentSubMenu.classList.add(subMenuNavigatedAwayClass);
    }

    // Set the link names for the go-back-links
    openSubMenus.forEach(submenu => {
      let linkText = "";
      const parentSubmenuLink = submenu.closest(".sub-menu__item--expanded")?.querySelector("a span");

      if (!parentSubmenuLink) {
        // Parent is the top level
        const parentLink = getSiblingByClass(submenu, "menu__link") as HTMLElement;
        linkText = parentLink?.querySelector("span")?.textContent || "";
      } else {
        linkText = parentSubmenuLink.textContent as string;
      }

      const parentLink = submenu.querySelector(".sub-menu__item--back-link a span:last-child") as HTMLElement;

      if (parentLink != null) {
        parentLink.innerText = linkText;
      }
    });
  };

  const stopEvent = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return {
    mobileToggleButton,
    toggleMobileMenu,
    openSubMenuMobile,
    closeSubMenuMobile,
    subMenuOpenClass
  };
};