const usePageHeader = () => {
  if (!document.querySelector(".page-header video")) {
    return;
  }

  // Play/pause video on page header
  const toggleVideo = (pageHeader: HTMLElement) => {
    const video = pageHeader?.querySelector(".page-header__video") as HTMLVideoElement;
    const button = pageHeader.querySelector(".page-header__video-button") as HTMLButtonElement;

    if (video.paused) {
      video.play();
      button.classList.remove("page-header__video-button--paused");
    } else {
      video.pause();
      button.classList.add("page-header__video-button--paused");
    }
  };

  // Add event listeners to all page headers videos
  document.querySelectorAll(".page-header").forEach((pageHeader) => {
    const button = pageHeader.querySelector(".page-header__video-button") as HTMLButtonElement;

    // Play/pause video on page header click
    pageHeader.addEventListener("click", () => {
      toggleVideo(pageHeader as HTMLElement);
    });

    // Play/pause video on button click
    button.addEventListener("click", (event) => {
      event.stopPropagation();
      toggleVideo(pageHeader as HTMLElement);
    });
  });
};

document.addEventListener('DOMContentLoaded', () => {
  usePageHeader();
});