export const useSitePagePartial = () => {
  const list: NodeListOf<HTMLElement> = document.querySelectorAll('.site-page-partial__content');

  function init(): void {
    if (list.length > 0) {
      list.forEach(item => {
        item.addEventListener('click', () => {
          makeClickable(item);
        });
      });
    }
  }

  function makeClickable(element: HTMLElement): void {
    const link = element.querySelector('.site-page-partial__link') as HTMLAnchorElement;
    window.location.href = link.getAttribute('href') as string;
  }

  init();
};

// Run the function on page load
useSitePagePartial();