import { debounce } from "@utils/debounce";

export const useDisturbanceService = () => {
  const disturbance = document.querySelector('.header__disturbance');
  const root = document.documentElement;

  interface IDisturbanceCookieData {
    closed: boolean;
    id: string;
  }

  function closeDisturbanceMessage() {
    disturbance?.classList.add('hidden');
    root.style.setProperty('--disturbance-height', 0 + "rem");
    const id = (disturbance?.querySelector('#disturbance-id') as HTMLInputElement).value;

    if (id != null) {
      const cookieData: IDisturbanceCookieData = {
        closed: true,
        id: id,
      };

      setCookie('OptimizelyFramework_disturbance', cookieData, 2); // Set the cookie to expire in 2 days
    }
  }

  function setCookie(name: string, value: IDisturbanceCookieData, days: number) {
    const expires = new Date();
    expires.setDate(expires.getDate() + days);
    const cookieValue = encodeURIComponent(JSON.stringify(value)) + `; expires=${expires.toUTCString()}; path=/`;
    document.cookie = `${name}=${cookieValue}`;
  }

  const setDisturbanceHeightVariable = debounce((): void => {
    if (disturbance) {
      const height = disturbance.clientHeight / 10;
      root.style.setProperty('--disturbance-height', height + "rem");
    }
  }, 50);

  if (disturbance) {
    setDisturbanceHeightVariable();
    window.addEventListener('resize', setDisturbanceHeightVariable);

    const disturbanceMessageCloseButton = disturbance.querySelector('#disturbance-message-close-button') as HTMLButtonElement;
    disturbanceMessageCloseButton.addEventListener('click', closeDisturbanceMessage);
  }

  return { setDisturbanceHeightVariable };
};

useDisturbanceService();