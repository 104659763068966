import { getParentsOfTypeUntilClass } from '@utils/helpers';
import { hideElement, showElement } from '@utils/helpers';

// Add listeners for the menu toggle buttons
document.querySelectorAll('.side-menu__toggle').forEach((element) => {
  element.addEventListener('click', () => {
    const submenu = element.nextElementSibling as HTMLUListElement;
    const parents = getParentsOfTypeUntilClass(submenu, 'li', 'side-menu');
    const topItem = parents[parents.length - 1];

    if (submenu.classList.contains('open')) {
      hideElement(submenu);
      setClosed(element, topItem, submenu, parents);
    } else {
      showElement(submenu, 300);
      setOpen(element, submenu, parents, topItem);
    }
  });
});

const setClosed = (element, topItem, submenu, parents) => {
  // Rotate chevron, remove open class
  element.querySelector(".icon")?.classList.remove("icon--rotate");
  submenu.classList.remove('open');

  // Remove expanded if at top level
  if (parents.length === 1) {
    topItem.classList.remove("side-menu__item--expanded");
  }
};

const setOpen = (element, submenu, parents, topItem) => {
  // Rotate chevron, add open class
  element.querySelector(".icon")?.classList.add("icon--rotate");
  submenu.classList.add('open');

  // Add expanded if at top level
  if (parents.length === 1) {
    topItem.classList.add("side-menu__item--expanded");
  }
};

// Open menu on load
const selectedItem = document.querySelector('.side-menu__item--selected');
if (selectedItem) {
  // Open selected item and its parents
  const parentMenus = getParentsOfTypeUntilClass(selectedItem, 'ul', 'side-menu');
  parentMenus.forEach((element) => {
    element.classList.add('open');
  });

  const submenu = selectedItem.querySelector("ul") as HTMLUListElement;

  // Add expanded if on top level and has children
  if (parentMenus.length === 1 && selectedItem.classList.contains('side-menu__item--has-children')) {
    const parentItems = getParentsOfTypeUntilClass(submenu, 'li', 'side-menu');
    const topItem = parentItems[parentItems.length - 1];
    topItem.classList.add("side-menu__item--expanded");
  }

  // Open the selected item's submenu
  if (submenu) {
    submenu.classList.add('open');

    // Rotate the icon since the menu is open
    selectedItem.querySelector(".icon")?.classList.add("icon--rotate");
  }
}